@import "../../mixins.scss";

.carousel {
  @include xs {
    @include f-r;
    @include c-sb;
  }
  @include xl {
    @include h-w;
    display: block;
    position: relative;
  }
  .carousel-content {
    @include h-w;

    position: relative;
    .carousel-child {
      @include xl {
        width: 100%;
      }
    }
  }
  .carousel-controls {
    @include xl {
      @include f-r;
      @include c-sb;
    }
    z-index: 5;
    .carousel-arrow {
      @include f-cc;
      @include xs {
        @include h-w-(20px);
        background-color: transparent;
        color: $white;
      }
      @include xl {
        @include h-w-(64px);
        border-radius: 50%;
        background-color: $white;
        color: $black;
        position: absolute;
        bottom: 100px;
      }
      cursor: pointer;
    }
    .carousel-back {
      @include xl {
        @include h-w-(64px);
        right: 35%;
      }
    }
    .carousel-forward {
      @include bp {
        @include h-w-(64px);
        right: 0;
      }
    }
  }
  .slick-slider {
    @include xs {
      @include f-r;
      @include c-c;
      height: max-content;
    }
    @include xl {
      height: 800px;
    }
  }
  .slick-list {
    @include xl {
      margin: 0px;
    }
  }
}