@import "../../mixins.scss";

.benefitCard {
  display: flex;
  width: 100%;
  align-items: flex-start;
  gap: 20px;
  @media screen and (max-width: 767px) {
    gap: 15px;
  }
  svg {
    width: 32px;
    height: 32px;
    flex-shrink: 0;
    fill: url(#purpleGradient) $bbPrimaryPurpleColor;
    @media screen and (max-width: 767px) {
      width: 24px;
      height: 24px;
    }
  }
  #svgGradient {
      width:0;
      height:0;
      position:absolute;
  }
  .textContainer{
      @include f-c-(10px);
      align-items: flex-start;
      h5 {
          @include h5;
          color: $bbPrimaryPurpleColor;
          text-transform: uppercase;
          font-weight: 600;
      }
      p {
        @include h3-caption;  
        font-weight: 400;
      }
  }
}
.light{
  svg{
    fill: $purpleAccent;
  }
  .textContainer{
    h5, p{
      color: $white;
    }
  }
}
.large{
  gap: 25px;
  @include md {
    gap: 30px;
  }
  svg{
    width: 50px;
    height: 50px;
    @include md {
      width: 70px;
      height: 70px;
    }
  }
}