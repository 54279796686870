@import "../../mixins.scss";

.NewsContainer {
  @include f-c;
  position: relative;
  align-items: center;
  padding: 60px 25px;
  overflow: hidden;
  box-sizing: border-box;
  width: 100%;
  * {
    box-sizing: border-box;
  }
  @include nav-bp{
    padding: 60px 120px;
  }
  .skeleton {
    background-color: rgba($color: $white, $alpha: 0.11);
    transform: unset;
    &::after {
      animation-duration: 1s;
      background: linear-gradient(
        90deg,
        transparent,
        rgba($color: $white, $alpha: 0.11),
        transparent
      );
    }
  }
  .subContainer {
    @include f-c-(15px);
    width: 100%;
    max-width: $max-width-no-padding;
    @include md {
      gap: 90px;
    }
    .titleContainer {
      @include f-c-(15px);
      align-items: flex-start;
      align-self: stretch;
      @include lg {
        @include f-r-(100px);
        align-items: center;
      }
      .title {
        @include f-c-(15px);
        width: 100%;
        .heading {
          @include h2;
          overflow-wrap: break-word;
          word-break: break-word;
          -ms-word-break: break-all;
          width: 100%;
        }
        .heading.light {
          background: $gradientAccentWhite;
          @include text-gradient;
        }
        .subtitle {
          width: 100%;
          margin: 0;
          @include h3-caption;
          @media screen and (max-width: $md) {
            overflow-wrap: break-word;
          }
        }
        .subtitle.light {
          color: $white;
        }
      }
      .lnkBtnAll {
        font-size: 18px;
        text-wrap: nowrap;
        padding-left: 0px;
        p{
          padding-left: 0px;
        }
        @include md {
          font-size: 20px;
        }
      }
    }
    .carouselContainer {
      @include f-c;
      padding-bottom: 45px;
      :global(.slick-slider) {
        min-height: 100%;
        :global(.slick-next),
        :global(.slick-prev) {
          position: absolute;
          top: unset;
          left: unset;
          right: 0;
          bottom: -73px;
          width: 28px;
          height: 28px;
          padding: 6px;
          fill: $bbPrimaryPurpleColor;
        }
        :global(.slick-next) {
          transform: rotateZ(90deg);
        }
        :global(.slick-prev) {
          transform: rotateZ(90deg);
          right: 40px;
        }
        :global(.slick-dots) {
          bottom: -70px;
          width: auto;
          left:0px;
        }
        :global(.slick-dots) li button {
          width: 10px;
          height: 10px;
          min-width: unset;
          border-radius: 2000px;
          background-color: transparent;
          @include fc-cc;
          ::before {
            opacity: 0;
          }
        }
        :global(.slick-dots) li {
          width: unset;
          height: unset;
          padding: 2px;
          border: 2px solid $bbPrimaryPurpleColor;
          border-radius: 2000px;
        }
        :global(.slick-dots) :global(.slick-active) button {
          background-color: $bbPrimaryPurpleColor;
        }
        :global(.slick-dots) li button::before {
          opacity: 0;
        }
      }
      :global(.slick-list) {
        min-height: 100%;
        overflow: unset;
        :global(.slick-track) {
          display: flex;
          column-gap: 45px;
          align-items: stretch;
          min-height: 100%;
          @include nav-bp{
            column-gap: 60px;
          }
          &::before,
          &::after {
            display: none;
          }
          :global(.slick-slide) {
            height: unset;
            & > div {
              width: 100%;
              height: 100%;
            }
          }
          @media screen and (max-width: $md) {
            column-gap: 30px;
          }
        }
      }
      .testimonial{
        display: flex !important;
        height: 100%;
        flex-direction: column;
        align-items: flex-start;
        gap: 25px;
        flex: 1 0 0;
        width: 100%;
        @include md {
          min-width: 350px;
        }
        @include nav-bp {
          min-width: 500px;
        }
        .imgWrap{
          align-items: flex-start;
          gap: 25px;
          width: 100%;
          height: 100%;
          @include md {
            display: flex;
          }
          .testimonialImg {
            aspect-ratio: 29/37;
            float: left;
            width: 40%;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            border-radius: 3px;
            flex-shrink: 0;
            margin: 0 20px 10px 0;
            @include md {
              float: unset;
              width: 40%;
              margin: 0;
            }
          }
        }
        .quote{
          @include h3-caption;
          color: $black;
          text-indent: -0.45em;
          height: 100%;
        }
        @supports ( hanging-punctuation: first) {
          .quote {
            text-indent: 0;
            hanging-punctuation: first;
          }
        }
        .footer{
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 20px;
          width: 100%;
          padding-top: 15px;
          border-top: 1.5px solid $bbPrimaryPurpleColor;
          height: 125px;
          flex-shrink: 0;
          @include nav-bp {
            flex-direction: row;
            align-items: center;
            height: 80px;
          }
          img, svg {
            max-height: 45px;
            max-width: 112px;
            width: 100%;
            background-position: left center;
            @include nav-bp {
              width: unset;
              height: 100%;
              max-height: 60px;
              max-width: 150px;
            }
          }
          svg {
            fill: $black;
            flex-shrink: 0;
          }
          .footerText{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 1px;
            width: 100%;
            h3 {
              color: $black;
              font: 700 20px/133% $font-1;
              text-transform: uppercase;
              @include nav-bp {
                font-size: 24px;
              }
            }
            h4 {
              color: $black;
              font: 400 16px/150% $font-1;
              font-style: italic;
              text-transform: uppercase;
              @include nav-bp {
                font-size: 18px;
              }
            }
          }
        }
      }
    }
    .carouselContainer.light {
      :global(.slick-slider) {
        :global(.slick-next),
        :global(.slick-prev) {
          fill: $white;
        }
        :global(.slick-dots) li {
          border: 2px solid $white;
        }
        :global(.slick-dots) :global(.slick-active) button{
          background-color: $purpleAccent;
        }
        :global(.slick-dots) :global(.slick-active) {
          border: 2px solid $purpleAccent;
        }
        :global(.slick-dots) li button::before {
          color: $purpleAccent;
        }
        .quote {
          color: $white;
        }
        .footer{
          border-top: 1.5px solid $white;
          svg {
            fill: $white;
          }
          img{
            filter: brightness(0) invert(1);
          }
          .footerText{
            h3 {
              color: $white;
            }
            h4 {
              color: $white;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: $md) {
    padding: 60px 20px;
  }
}

:export {
  gradientWhitetoLightPurple: $gradientWhiteToLightPurple;
}
