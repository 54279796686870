@import "../../mixins.scss";

.upload {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  margin-bottom: auto;
  font-size: 14px;
  overflow-y: auto;
  .title {
    margin-bottom: 32px;
    color: $medium;
  }
  .help {
    margin: 10px 0;
    display: flex;
    align-items: center;
    color: $helpTextColor;
    font-size: 15px;
    cursor: pointer;
    font-family: $font-1;
    font-weight: 700;
    letter-spacing: 0.76px;
  }
  .content {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 100%;
    justify-content: center;
    align-items: center;
    .fileCount {
      font-size: 10px;
      letter-spacing: 1px;
      margin: 15px 0 0;
      text-transform: uppercase;
      font-family: $font-1;
      font-weight: 700;
      color: $helpTextColor;
    }
    .textContent {
      margin-left: 0;
      margin-top: 20px;
      justify-content: center;
      display: flex;
      flex-direction: column;
      font-size: 14px;
      letter-spacing: 0.56px;
      h3 {
        font-weight: 700;
      }
    }
    .dropContainer {
      text-align: center;
    }
    .files {
      align-items: flex-start;
      justify-items: flex-start;
      flex: 1;
      overflow-y: auto;
      margin-left: 0;
      width: 100%;
      min-height: unset;
      max-height: 200px;
      margin-top: 20px;
      .row {
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: space-between;
        padding: 8px;
        overflow: hidden;
        box-sizing: border-box;
        border-radius: 6px;
        position: relative;
        margin-bottom: 6px;
        background-color: rgba($color: $helpTextColorRgb, $alpha: 0.1);
        &.error {
          background-color: rgba($color: $error, $alpha: 0.2);
        }
        .fileNameContainer {
          font-size: 12px;
          color: $dark;
          word-break: break-all;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .fileName {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .msgError {
            display: flex;
            align-items: center;
            color: $error;
            font-size: 12px;
            float: right;
            margin-left: 10px;
            white-space: nowrap;
            .helpBtn {
              color: $blueViolet500;
              font-size: 18px;
              margin-left: 8px;
              cursor: pointer;
            }
          }
          .dNone {
            display: none;
          }
        }
        .progressWrapper {
          display: flex;
          flex-grow: 1;
          flex-direction: row;
          align-items: center;
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
        }
        .checkIcon {
          width: 11px;
          margin-left: 10px;
          line-height: 18px;
          cursor: pointer;
          font-size: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-shrink: 0;
          svg {
            width: 100%;
            path {
              fill: rgba($color: $helpTextColorRgb, $alpha: 0.7);
            }
          }
        }
        .dNone {
          display: none;
        }
      }
    }
    .files:empty {
      display: none;
    }
    ::-webkit-scrollbar {
      width: 5px;
      height: 10px;
    }
    ::-webkit-scrollbar-track {
      background-color: rgba($color: $black, $alpha: 0.1);
    }
    ::-webkit-scrollbar-thumb {
      background-color: rgba($color: $black, $alpha: 0.2);
      border-radius: 8px;
      max-height: 100px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background-color: rgba($color: $black, $alpha: 0.4);
    }
  }
  .actions {
    display: flex;
    flex: 1;
    width: 100%;
  }
  @media screen and (max-width: 576px){
    max-width: 300px;
  }
}

.btn {
  font-size: 14px;
  display: inline-block;
  height: 36px;
  padding: 6px 16px;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 0;
  border-radius: 2px;
  background: $blueViolet500;
  color: $white;
  outline: 0;
}

.btn:disabled {
  background: $btnDeactivated;
  cursor: default;
}
.uploadButtons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  bottom: 0;
  z-index: 4;
  left: 0;
  background: $white;
  box-shadow: 0px -6px 20px rgba($color: $black, $alpha: 0.2);
  padding: 15px;
  flex-direction: column;
  position: fixed;
  .actionCheck {
    text-align: left;
    font-size: 16px;
    margin: 0 10px;
    label {
      margin-bottom: 0;
    }
    span {
      font-size: 14px;
    }
    .checkbox {
      color: $blueViolet500 !important;
      &:hover {
        background-color: transparent !important;
      }
    }
  }
  .uploadReportsButtonContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    .quickLinksContainer {
      margin-left: 10px;
      display: flex;
      flex-direction: column;
      width: 350px;
      > p {
        position: absolute;
        top: 10px;
        font-size: 14px;
        letter-spacing: -0.34px;
        line-height: 13px;
        span {
          display: none;
        }
      }
      .selectContainer {
        display: flex;
        align-items: center;
        & > div {
          width: 130px;
          height: 50px;
          flex-grow: 1;
        }
        button {
          width: 80px;
          border: 1px solid $ctaColor;
          margin: 0;
          margin-left: 5px;
          &:disabled {
            border: 1px solid $greyColor;
          }
        }
      }
    }
    button {
      width: 280px;
      max-width: 280px;
      font-size: 14px;
      padding: 12px 20px;
      border-radius: 25px;
      background-color: transparent;
      text-align: center;
      margin: 5px;
      color: $ctaColor;
      border: none;
      text-transform: uppercase;
      letter-spacing: 2.8px;
      font-family: $font-1;
      font-weight: 700;
      &.secondary {
        &:hover {
          text-decoration: underline;
        }
        &:disabled {
          color: rgba($color: $ctaColorRgb, $alpha: 0.4);
          cursor: not-allowed;
        }
      }
      &.primary {
        color: $white;
        background-color: $ctaColor;
        &:disabled {
          background-color: rgba($color: $ctaColorRgb, $alpha: 0.4);
          cursor: not-allowed;
        }
      }
    }
    &.dummyBtnContainer {
      width: 100%;
      flex-direction: column;
      padding: 0 10px;
      button {
        width: 100%;
        font-size: 14px;
        padding: 10px 15px;
        margin: 5px;
        max-width: unset;
        &.secondary {
          border: none;
          text-decoration: underline;
          color: $ctaColor;
        }
        &.demoBtn {
          background-color: $ctaColor;
          font-family: $font-1;
          font-weight: 700;
          color: $white;
          border: none;
          border-radius: 25px;
          padding: 12px 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            width: 35px;
            margin-right: -10px;
            stroke: $white;
          }
        }
      }
    }
  }
}

.infoIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  font-style: normal;
  height: 17px;
  width: 17px;
  border: none;
  color: $white;
  border-radius: 50%;
  cursor: pointer;
  background-color: $greyColor;
  margin-left: 15px;
  &.infoPrimary {
    background-color: $helpTextColor;
    color: $white;
    margin-right: 10px;
    margin-left: 0;
  }
}

.infoIcon::after {
  font-size: 11px;
  text-transform: lowercase;
  content: "i";
  letter-spacing: 0px;
  line-height: 12px;
  position: relative;
  top: 1px;
  font-family: $font-1;
  font-weight: 700;
}

.findReportPopupContainer {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 90%;
  padding: 20px 20px 20px 25px;
  max-width: 600px;
  background-color: $white;
  border-radius: 20px;
  * {
    box-sizing: border-box;
  }
  .instructionInfoContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    .btnLink {
      margin-top: 20px;
      padding: 10px 20px;
      display: flex;
      align-items: center;
      border: 1px solid $helpTextColor;
      border-radius: 30px;
      color: $helpTextColor;
      background-color: transparent;
      width: fit-content;
      font-size: 12px;
      svg {
        width: 15px;
        margin-right: 10px;
        path {
          fill: $helpTextColor;
        }
      }
    }
    .otherContent {
      font-size: 16px;
      ul {
        list-style: none;
        padding-inline-start: 20px;
      }
      li::before {
        content: "\2022";
        line-height: 16px;
        color: $blueViolet500;
        font-weight: 600;
        display: inline-block;
        margin-left: -20px;
        margin-right: 10px;
      }
    }
    .instructionsContent {
      display: contents;
      overflow-y: auto;
    }
    h4.infoTitle {
      font-size: 14px;
      align-self: stretch;
      font-family: $font-1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0px;
      svg {
        cursor: pointer;
      }
    }
  }
}

.modalOverlay {
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  backdrop-filter: blur(6px);
  background-color: rgba($color: $black, $alpha: 0.1);
  overflow: auto;
  z-index: 4;
}

.hideOverflow{
  overflow: hidden;
}

.uploadContainer {
  @include f-c;
  max-height: 250px;
  overflow: hidden;
}