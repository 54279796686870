@import "../../mixins.scss";

.swiper-vertical > .swiper-wrapper {
  display: flex;
  align-items: center;
  max-height: 400px;
}
.swiper-slide-active {
  display: flex;
  align-items: center;
  max-height: 400px;
}
.swiper-horizontal > .swiper-scrollbar {
  height: 0px;
}
.swiper-scrollbar.swiper-scrollbar-vertical,
.swiper-vertical > .swiper-scrollbar {
  width: 0px;
}

.swiper-pagination-bullet,
.swiper-pagination-bullet-active {
  height: 17px !important;
  width: 17px !important;
  box-sizing: border-box !important;
  border: 3px solid;
}

:not(.swiper-pagination-bullet-active).swiper-pagination-bullet {
  border-color: #dddbf1;
  background-color: transparent !important;
}

.swiper-pagination-bullet {
}

.swiper-pagination-bullet-active {
  border-color: linear-gradient(
    223.76deg,
    #5a49a6 19.41%,
    #8a65eb 67.36%
  ) !important;
}

.swiper-pagination {
  display: flex;
  align-items: center;
  gap: 10px;
  bottom: 0px !important;
  text-align: left !important;
}
.render-bullet {
  position: relative;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  border-radius: 18px;
  border: 2px solid $bbPrimaryPurpleColor;
  box-sizing: border-box;
}
.render-bullet svg {
  width: 18px;
  height: 18px;
  display: flex;
}
.render-bullet.render-bullet-active::after {
  content: " ";
  display: flex;
  height: 10px;
  width: 10px;
  background-color: $blueViolet700;
  border-radius: 11px;
}
