@import "../../mixins.scss";

.videoHeader{
  .video {
    width: 100vw;
    @include fullViewportHeightwHeader;
    position: absolute;
    object-fit: cover;
    top: $nav-height-m;
    left: 0;
    z-index: -1;
    @include nav-bp {
      top: $nav-height;
    }
  }
}

.landing {
  width: 100vw;
  @include fullViewportHeightwHeader;
  z-index: 10;
  @include fc-cc;
  padding: 30px 25px 45px 25px;
  gap: 60px;
  @include nav-bp {
    padding: 90px 120px 80px 120px;
    gap: 75px;
  }
  .titleCtn {
    @include fc-cc;
    width: 100%;
    @include h1;
    text-align: center;
    .primary {
      color: $white;
    }
    .secondary {
      color: $purpleAccent;
    }
  }
  .landingActions {
    display: flex;
    flex-direction: column-reverse;
    align-self: stretch;
    justify-content: center;
    row-gap: 20px;
    column-gap: 25px;
    .actionButton, a {
      width: 100%;
      order: 1;
    }
    @include nav-bp {
      flex-direction: row;
      .actionButton, a {
        width: 325px;
        order: 2;
        height: 60px;
      }
    }
  }
}
