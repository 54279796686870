@import "../../mixins.scss";

.content {
    @include fc-cc;
    background-color: $white;
    border-radius: 20px;
    max-width: 500px;
    gap: 30px;
    width: 100%;
    padding: 25px;
    @include nav-bp {
      gap: 50px;
      padding: 40px;
    }
    .titleContainer{
      @include fc-cc;
      width: 100%;
      gap: 15px;
      @include nav-bp {
        gap: 25px;
      }
      .icon{
        width: 45px;
        height: 45px;
        margin-top: 10px;
        @include md {
          width: 64px;
          height: 64px;
          margin-top: 20px;
        }
        svg{
          flex-shrink: 0;
          width: 100%;
          height: 100%;
          fill: url(#purpleGradient) $bbPrimaryPurpleColor;
        }
        #svgGradient {
          width:0;
          height:0;
          position:absolute;
        }
      }
      .title {
        @include h4;
        color: $blueVioletColor;
        text-transform: unset;
        text-align: center;
      }
      .subtitle {
        @include h4-caption;
        text-align: center;
      }
    }
    .homeLink {
      width: 100%;
      @include fc-cc;
      gap: 15px;
      .buttonDescripton{
        @include h5-caption;
        text-align: center;
      }
    }
}

//animations
@keyframes twistBack {
  0% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(0);
  }
}
@keyframes twistForward {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(180deg);
  }
}

.widgetBoxForm {
  @include f-c-(30px);
  padding: 20px 25px 25px;
  align-items: center;
  border-radius: 15px;
  background: $white;
  max-width: 500px;
  width: 100%;
  justify-content: center;
  @include nav-bp{
    padding: 40px 50px 50px;
  }
  .heading{
    @include f-c-(5px);
    align-items: flex-start;
    align-self: stretch;
    .ogTitle{
      @include h5;
      font-weight: 600;
      color: $blueVioletColor;
    }
    .ogSubtitle {
      @include h6-caption;
    }
  }

  .form {
    @include f-c-(20px);
    @include lg {
      @include f-c-(25px);
    }
  }
  & .spinnerDiv {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .tooSmall {
    width: 100%;
    color: $neutralPurple900;
    font: 400 normal 12px/150% $font-1;
    font-style: italic;
    @include lg {
      font-size: 14px;
    }
    .applyLink{
      color: $blueVioletColor;
      font-weight: 600;
      font-style: normal;
    }
  }
  .checkboxClass {
    @include f-r-(10px);
    font-size: 12px;
    align-items: flex-start;
    font-family: $font-1;
    a {
      display: inline;
      font-size: 12px;
      color: $blueVioletColor;
    }
  }
  .submit {
    width: 100%;
    max-width: none;
    max-height: 49px;
    svg{
      width: 15px;
      height: 15px;
      flex-shrink: 0;
      * {
        fill: $white;
      }
    }
  }
  .form-upload-container {
    @include f-c-(10px);
    align-self: stretch;
    .drawer {
      @include f-r-(20px);
      align-items: center;
      align-self: stretch;
      p {
        color: $neutralPurple900;
        font: 600 normal 14px/150% $font-1;
        width: 100%;
        margin: 0;
        @include lg {
          font-size: 16px;
        }
      }
      button {
        @include f-r-(8px);
        justify-content: center;
        align-items: center;
        fill: none;
        border: none;
        background-color: transparent;
        animation: twistBack 0.5s ease 0s 1 normal forwards;
        svg{
          width: 15px;
          height: 15px;
          * {
            fill: $blueVioletColor;
          }
        }
      }
      & .open {
        animation: twistForward 0.5s ease 0s 1 normal forwards;
      }
    }

    #dropdown-drawer {
      display: none;
    }
  }
}
.spin {
  color: $purpleAccent;
  height: 22px;
  animation: spin 0.8s infinite linear;
  margin: auto;
  @media screen and (max-width: 990px) {
    width: 20px;
    height: 20px;
  }
}
@keyframes spin {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}
