@import "../../mixins.scss";

.cornerSection {
  position: relative;
  @include whiteBG;
  .left{
    position: absolute;
    bottom: 0;
    left: 0;
    margin: 0 0 -1px 0;
    fill: $backgroundBlack;
    width: 60px;
    height: 60px;
    @include nav-bp {
        width: 120px;
        height: 120px;
    }
  }
  .right{
    position: absolute;
    fill: $backgroundBlack;
    top: 0;
    right: 0;
    width: 60px;
    height: 60px;
    margin:-1px 0 0 0;
    @include nav-bp {
        width: 120px;
        height: 120px;
    }
  }
}