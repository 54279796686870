@import "../../mixins.scss";

.faqs {
  width: 100%;
  .faq-list {
    @include f-c;
    @include fs-fs;
    width: 100%;
  }
}
