@import "../../mixins.scss";

.emptyDiv {
  min-height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  h1 {
    color: $bbPrimaryPurpleColor;
    font-size: 16px;
    text-align: center;
  }
}

.articleCtn {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  max-width: 1240px;
  padding: 90px 20px;
  gap: 75px;
  .titleContainer {
    @include f-r-(100px);
    align-items: center;
    align-self: stretch;
    justify-content: space-between;
    @media screen and (max-width: $md) {
      @include f-c-(30px);
      align-items: flex-start;
    }
    .title {
      @include f-c-(15px);
      width: fit-content;
      .heading {
        @include font-acumin-sb;
        font-size: 100px;
        font-weight: 700;
        line-height: 100px;
        margin: 0;
        background: $gradientDarkToBrightPurple;
        text-transform: uppercase;
        @include text-gradient;
        width: fit-content;
        line-height: 0.9;
        word-break: break-word;
        -ms-word-break: break-all;
        @media screen and (max-width: $md) {
          font-size: 75px;
          overflow-wrap: break-word;
        }
      }
      .subtitle {
        width: 100%;
        margin: 0;
        font-size: 18px;
        font-weight: 500;
        color: $bbPrimaryPurpleColor;
        @media screen and (max-width: $md) {
          overflow-wrap: break-word;
          font-size: 16px;
        }
      }
    }
  }
  .carouselContainer {
    @include f-c-(0px);
    :global(.slick-slider) {
      :global(.slick-next),
      :global(.slick-prev) {
        position: absolute;
        top: unset;
        left: 0;
        bottom: -25px;
        width: 24px;
        height: 24px;
        padding: 0;
        color: $bbPrimaryPurpleColor;
      }
      :global(.slick-next) {
        transform: rotateZ(90deg);
        left: 40px;
      }
      :global(.slick-prev) {
        transform: rotateZ(90deg);
      }
      svg {
        path {
          fill: $bbPrimaryPurpleColor;
        }
      }
    }
    :global(.slick-list) {
      :global(.slick-track) {
        display: flex;
        column-gap: 30px;
        &::before,
        &::after {
          display: none;
        }
        :global(.slick-slide) {
          height: unset;
          & > div {
            height: 100%;
          }
        }
      }
    }
    .lnkBtnAll {
      @include f-r-(5px);
      @include font-acumin-b;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
      font-size: 21px;
      text-transform: uppercase;
      text-decoration: underline;
      text-wrap: nowrap;
      color: $bbPrimaryPurpleColor;
      @include md {
        justify-content: center;
      }
      svg {
        margin-left: 10px;
        margin-bottom: 2px;
        height: 20px;
        width: 20px;
        path {
          fill: $bbPrimaryPurpleColor;
        }
      }
      @media screen and (max-width: $md) {
        font-size: 21px;
      }
    }
  }
  @media screen and (max-width: $md) {
    padding: 60px 20px;
    gap: 50px;
  }
}

.gridContainer {
  display: grid;
  padding: 60px 120px;
  grid-template-columns: repeat(3, 1fr);
  gap: 50px;
  justify-items: center;
  color: $bbPrimaryPurpleColor;
  width: 100%;
  max-width: $max-width;
  .articleContainer{
    width: 100%;
  }
  &.marginBottom {
    margin-bottom: 50px;
  }
  @media screen and (max-width: 989px) {
    padding: 30px 25px;
  }
  @media screen and (max-width: 767px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: 500px) {
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
  }
  
}

.resourceLink {
  @include f-c-(25px);
  width: 100%;
  padding-bottom: 15px;
  @include nav-bp{
    max-width: 250px;
    padding-bottom: 0px;
  }
  h3 {
    @include h4;
  }
  .links {
    display: flex;
    gap: 10px 20px;
    flex-wrap: wrap;
    width: 100%;
    align-content: flex-start;
    @include nav-bp{
      flex-direction: column;
      flex-wrap: nowrap;
      gap: 15px 20px;
    }
    .resourceInnerLink {
      padding: 0px;
      p {
        padding-left: 0px;
        white-space: nowrap;
      }
    }
  }
  &.light{
    h3{
      color: $white;
    }
  }
}

.articlePagination {
  width: 100%;
  max-width: $max-width;
  padding: 0 25px 40px 25px;
  @include nav-bp{
    padding: 0 120px 40px 120px;
  }
  :global(.MuiStack-root) {
    position: relative;
    nav{
      width: 100%;
      ul {
        gap: 15px;
        justify-content: center;
        width: 100%;
        button {
          color: transparent;
          box-sizing: border-box;
        }
      }
    }
  }
  li:first-child, li:last-child {
    button{
      display: block;
      background-color: transparent;
      border: none;
      border-radius: none;
      box-shadow: none;
      svg {
        flex-shrink: 0;
        path {
          fill: $white;
        }
      }
    }
  }
  li:first-child {
    position: absolute;
    left: 0;
    top: 8px;
    button {
      padding-right: 10px;
    }
  }
  li:last-child {
    position: absolute;
    right: 0;
    top: 8px;
    button {
      padding-left: 10px;
    }
  }
  &.extraPadding {
    padding: 0% 10% 5% 10%;
  }
}

.pagination {
  :global(.MuiButtonBase-root) {
    width: 8px;
    height: 8px;
    min-width: unset;
    box-shadow:
          0 0 0 3px $backgroundBlack,
          0 0 0 5px $white;
    border-radius: 2000px;
    background-color: $backgroundBlack;
    &:global(.MuiPaginationItem-root.Mui-selected) {
      box-shadow:
            0 0 0 3px $backgroundBlack,
            0 0 0 5px $purpleAccent;
      border-radius: 2000px;
      background-color: $purpleAccent;
    }
  }
}

.noData {
  min-height: 400px;
  @include f-c-(20px);
  justify-content: center;
  h3 {
    @include h1-caption;
    color: $white;
    width: 100%;
  }
  max-width: $max-width;
  width: 100%;
  padding: 30px 25px;
  @include nav-bp{
    padding: 90px 120px;
    gap: 30px;
  }
}