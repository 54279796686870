@import "../../mixins.scss";

.train {
  width: 100%;
  overflow-x: auto;
  .train-rail {
    @include f-r-(20px);
    @include c-c;
    width: max-content;
  }
  .train-car {
    height: fit-content;
    width: fit-content;
    display: flex !important;
    justify-content: center;
  }
  .slick-list {
    height: 300px;
    .slick-track {
      height: 100%;
      .slick-slide {
        display: flex;
        justify-content: center;
        & > div {
          width: 90%;
          padding: 0 10px;
        }
        .train-car {
          height: 100%;
          align-items: center;
          // iframe.w-v-video {
          //   width: 100%;
          //   max-height: 270px;
          //   height: 100%;
          // }
        }
      }
    }
  }
  .carousel .slick-slider {
    height: max-content;
    .carousel-controls {
      .carousel-forward {
        right: 0;
        left: unset;
        bottom: 0;
        position: relative;
        background-color: transparent;
      }
      .carousel-back {
        right: unset;
        left: unset;
        bottom: 0;
        position: relative;
        background-color: transparent;
      }
    }
  }
}
.platform-button {
  @include f-c;
  @include c-c;
  width: 100%;
  position: relative;
  height: 48px;
  .platform-button-holder {
    position: absolute;
    $platform-button-hw: 36px;
    height: $platform-button-hw;
    width: $platform-button-hw;
    transition: width 1s, height 1s, transform 1s;
  }
  &:hover .platform-button-holder {
    $platform-button-hover-hw: 48px;
    height: $platform-button-hover-hw;
    width: $platform-button-hover-hw;
  }
}
$button-translate-distance: 12px;
.platform-rail-up {
  &:hover .platform-rail-up-holder {
    transform: translateY(-$button-translate-distance);
  }
}
.platform-rail-down {
  &:hover .platform-rail-down-holder {
    transform: translateY($button-translate-distance);
  }
}
