@import "../../mixins.scss";
@import "../../sass/constants.scss";

.widgetBox {
  display: flex;
  box-sizing: border-box;
  width: 100%;
  min-width: 300px;
  background-clip: border-box;
  background-color: $white;
  border-radius: 15px;
  color: $darkPurpleColor;
  font: 700 18px/28px $font-1;
  overflow: hidden;
  @include lg {
    min-width: 398px;
  }
  .content {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    font-style: normal;
    font-weight: 700;
    gap: 30px;
    padding: 30px 0 25px;
    @include lg{
      min-height: 250px;
      padding: 50px 45px 40px;
    }
    .text {
      color: $bbPrimaryPurpleColor;
      font-size: 27px;
      letter-spacing: 1.35px;
      .titleDark {
        color: $black;
      }
      @media screen and (max-width: 767px) {
        line-height: 35px;
      }
    }
    .subtitle {
      color: $darkPurpleColor;
      width: 100%;
      span {
        font-weight: 700;
      }
      font-weight: 500;
    }
    .tooSmall {
      color: $darkPurpleColor;
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
      a {
        color: $darkPurpleColor;
        font-weight: 600;
      }
      ol {
        display: flex;
        flex-direction: column;
        text-align: left;
        row-gap: 5px;
        padding-inline-start: 18px;
        margin: 30px 0 0;
      }
    }
    .footerText {
      color: #382b58;
    }
    .btn {
      min-width: 100%;
      margin: 0;
      button {
        min-width: 100%;
        padding: 0px 35px;
      }
      .serviceLinkBtn {
        padding: 0;
        margin: 0;
        min-width: 100%;
      }
    }
  }
  @media screen and (max-width: 990px) {
    flex-direction: column-reverse;
    padding: 40px;
  }
  @media screen and (max-width: 767px) {
    padding: 20px;
  }
}

.forgotPasswordBox {
  .forgotPasswordDiv {
    justify-content: center !important;
    .header {
      width: 100%;
      @include md {
        height: 100%;
      }
    }
    .forgotPassword {
      width: 100%;
      @include md {
        height: 100%;
      }
      form {
        display: flex;
        flex-direction: column;
        gap: 25px;
        @include md {
          gap: unset;
          justify-content: space-between;
          height: 100%;
        }
      }
      .btnDiv {
        @include f-c-(20px);
        align-items: center;
        @include md {
          flex-direction: row-reverse;
          justify-content: space-between;
        }
        .resetButton{
          width: 100%;
          @include md {
            width: unset;
          }
        }
        .loginLink {
          color: $tooltipFontColor;
          font: 500 14px/20px $font-1;
          text-decoration-line: underline;
          cursor: pointer;
        }
      }
    }
    .passwordResetDiv {
      @include fc-cc;
      gap: 30px;
      height: 100%;
    }
    .passwordLoginDiv {
      color: $darkPurpleColor;
      font: 500 12px/150% $font-1;
      @include md {
        font-size: 14px;
      }
      span {
        color: $tooltipFontColor;
        text-decoration-line: underline;
        cursor: pointer;
      }
    }
  }
}
