@import "../../mixins.scss";

.glossary {
  width: 100%;
  h2 {
    @include h2
  }

  .gloss_list {
    display: flex;
    flex-direction: column;
    row-gap: 0px;
    column-gap: 120px;
    max-width: $max-width;
    width: 100%;
    padding: 30px 25px;
    align-content: flex-start;
    flex-wrap: wrap;
    @include nav-bp{
      padding: 60px 120px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: start;
    }
  }
}

.overlayClass {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: $white;
  border-radius: 12px;
  max-width: 750px;
  width: 95%;
  outline: none;
  padding: 40px 20px 20px 20px;  
  box-sizing: border-box;
  * {
    box-sizing: border-box;
  }
  .icon {
    padding: 10px 20px;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    svg {
      width: 14px;
      height: 14px;
      fill: $bbPrimaryPurpleColor;
    }
  }
  .iframeContainer {
    aspect-ratio: 16/9;
    max-width: 100%;
    .iframe {
      width: 100%;
      height: 100%;
    }
  }
}



.noData {
  width: 100%;
  display: flex;
  min-height: 200px;
}
