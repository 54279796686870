@import "../../mixins.scss";

.fixed-button {
  @include xs {
    bottom: 40px;
    right: 20px;
  }
  @include md {
    bottom: 70px;
    right: 30px;
  }
  @include lg {
    bottom: 70px;
    right: 50px;
  }
  width: 245px;
  height: 50px;
  border-radius: 39px;
  background-color: #FEA98C;
  position: fixed;
  box-sizing: border-box;
  padding: 15px 50px;
  z-index: 6;
}