@import "../../mixins.scss";

.slide{
    display: flex;
    flex-direction: row;
    width: 100vw;
    align-items: center;
    justify-content: center;
    gap: 15px;
    padding: 20px 10px 30px 25px;
    @include nav-bp {
        gap: 45px;
        padding: 90px 45px 90px 120px;
    }
}

.indicator{
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 0;
    list-style-type: none;
    .bulletText{
        display: none;
    }
    ::marker{
        display: none;
    }
    .bulletContainer{
        @include fc-cc;
        padding: 2px;
        border: 2px solid $white;
        border-radius: 2000px;
        .bullet{
            width: 10px;
            height: 10px;
            min-width: unset;
            border-radius: 2000px;
            background-color: transparent;
            @include fc-cc;
        }
        &.active{
            border: 2px solid $purpleAccent;
            .bullet {
                background-color: $purpleAccent;
            }
        }
    }
    
}

.container.light{
    @include whiteBG;
    position: relative;
    .bulletContainer{
        border: 2px solid $bbPrimaryPurpleColor;
        &.active{
            .bullet{
                background-color: $bbPrimaryPurpleColor;
            }
        }
    }
    .left {
        position: absolute;
        overflow-y: hidden !important;
        z-index: 100;
        bottom: 0;
        left: 0;
        margin: 0 0 -1px 0;
        width: 60px;
        height: 60px;
        svg{
            width: 60px;
            height: 60px;
            fill: $backgroundBlack;
        }
        @include nav-bp {
            width: 120px;
            height: 120px;
            svg{
            width: 120px;
            height: 120px;
            } 
        }
    }
    .right {
        position: absolute;
        overflow-y: hidden !important;
        z-index: 100;
        top: 0;
        right: 0;
        width: calc(60px + $nav-height-m);
        height: calc(60px + $nav-height-m);
        svg{
          width: calc(60px + $nav-height-m);
          height: calc(60px + $nav-height-m);
          fill: $backgroundBlack;
        }
        margin:-1px 0 0 0;
        @include nav-bp {
          width: calc(120px + $nav-height);
          height: calc(120px + $nav-height);
          svg{
            width: calc(120px + $nav-height);
            height: calc(120px + $nav-height);
          } 
        }
    }
}

