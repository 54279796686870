@import "../../mixins.scss";

.aboutSwiperCarousel {
  @include f-cc;
  margin: 0px;
  @include xl {
    @include f-c;
    @include fs-c;
  }
  width: 100%;
  .wrapper {
    width: inherit;
    height: max-content;
    position: relative;
    .component {
      @include xs {
        @include f-r;
        @include c-c;
      }
      @include xl {
        position: relative;
      }
      width: 100%;
      .block {
        @include f-c;
        @include xs {
          @include fs-sb;
          // margin: 0px;
          width: 100%;
        }
        @include sm {
          @include fs-fs;
        }
        @include bp {
          // margin: 0px 5%;
          width: 70%;
        }
        text-align: left;
        .num {
          color: #dddbf1;
          font: normal 14px/24px $font-1;
          letter-spacing: 0.7px;
          text-align: left;
          padding-bottom: 10px;
        }
        .title {
          padding-bottom: 15px;
          @include f-c-(8px);
          @include xs {
            @include fs-sb;
            text-align: left;
            font: 700 30px/36px $font-1;
          }
          @include md {
            @include fs-c;
            text-align: left;
            font: 700 48px/42px $font-1;
          }
          background: linear-gradient(
            223.76deg,
            #5a49a6 19.41%,
            #8a65eb 67.36%
          );
          @include text-gradient;
          color: transparent;
          font: 700 35px/42px $font-1;
          letter-spacing: 1px;
        }
        .text {
          color: #5849a6;
          font: normal 24px/30px $font-1;
          letter-spacing: 0.7px;
          text-align: left;
          width: 100%;
          margin-bottom: 40px;
          @media screen and (max-width: 767px) {
            font-size: 20px;
          }
        }
      }
    }
    .arrow {
      cursor: pointer;
      position: absolute;
      bottom: 0px;
      z-index: 3;
      width: 24px;
      height: 24px;
      svg {
        width: 100%;
        height: 100%;
      }
    }
    .previous {
      transform: rotateZ(90deg);
      @include xs {
        left: calc(100% - ((32px * 2) + 24px));
      }
      @include bp {
        left: calc(70% - ((32px * 2) + 24px));
      }
    }
    .next {
      transform: rotateZ(-90deg);
      @include xs {
        left: calc(100% - 32px);
      }
      @include bp {
        left: calc(70% - 32px);
      }
    }
  }
}
