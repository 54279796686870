$xs: 0px;
$xs-sm: 375px;
$sm: 576px;
$sm-md: 672px;
$md: 768px;
$md-lg: 990px;
$lg: 1048px;
$lg-xl: 1224px;
$xl: 1400px;
$xl-xxl: 1650px;
$xxl: 1900px;
$xxl-xk: 2200px;
$xk: 2500px;

$c-lg: 1024px;
$c-xl: 1441px;
$c-xxl: 1920px;
$nav-bp: $lg;

$xs-n: 0;
$xs-sm-n: 288;
$sm-n: 576;
$sm-md-n: 672;
$md-n: 768;
$md-lg-n: 908;
$lg-n: 1048;
$lg-xl-n: 1224;
$xl-n: 1400;
$xl-xxl-n: 1650;
$xxl-n: 1900;
$xxl-xk-n: 2200;
$xk-n: 2500;

$c-lg-n: 1024;
$c-xl-n: 1440;
$c-xxl-n: 1920;

$nav-height: 85px;
$nav-height-m: 60px;
$max-width: 1900px;
$max-width-no-padding: 1660px;

$ai-st: "stretch";
$ai-fs: "flex-start";
$ai-s: "start";
$ai-fe: "flex-end";
$ai-e: "end";
$ai-c: "center";
$ai-b: "baseline";
$ai-sb: "space-between";

$jc-fs: "flex-start";
$jc-fe: "flex-end";
$jc-c: "center";
$jc-s: "start";
$jc-e: "end";
$jc-l: "left";
$jc-r: "right";
$jc-sb: "space-between";
$jc-sa: "space-around";

$font-a: Poppins;
$font-c: sans-serif;
$font-1: $font-a, $font-c;
$font-acumin: acumin-variable, $font-1;

//Figma Variables

$white: #ffffff;
$black: #000000;

$blueViolet1000: #1A1632;
$blueViolet900: #352C64;
$blueViolet800: #3E3374;
$blueViolet700: #463a85;
$blueViolet600: #4F4295;
$blueViolet500: #5849A6;
$blueViolet400: #8A80C1;
$blueViolet300: #9B92CA;
$blueViolet200: #ACA4D3;
$blueViolet100: #CDC8E4;
$blueViolet50: #DEDBED;
$blueViolet25: #EEEDF6;

$neutralPurple50: #E8E7F6;
$neutralPurple100: #DDDBF1;

$success25: #F5FCF4;
$success50: #ECF9EA;
$success100: #E2F6DF;
$success200: #C5EDC0;
$success300: #A9E5A0;
$success400: #8CDC81;
$success500: #6FD361;
$success600: #569A4D;
$success700: #457B3E;
$success800: #345C2E;
$success900: #223E1F;

$error25: #FEE5E5;
$error50: #FCCBCB;
$error100: #FCCBCB;
$error200: #F76262;
$error300: #EA3333;
$error400: #C92C2C;
$error500: #A72424;
$error600: #861D1D;
$error700: #641616;
$error800: #430F0F;
$error900: #210707;

$snuff: #dddbee;
$snuff-2: $neutralPurple100;

// main color
$neutralBlueColor: var(--app-primary-background-color);
$bbPrimaryColor: var(--primary-color);
$helpTextColor: var(--help-text-color);
$ctaColor: var(--cta-color);

$light: #e6e6e6;

// main rgb color
$helpTextColorRgb: var(--help-text-color-rgb);
$ctaColorRgb: var(--cta-color-rgb);

// side color
$bbPrimaryPurpleColor: $blueViolet500;
$error: #C92C2C;
$spotify: #1db954;
$veryLight: #f1f1f1;
$grey: #999999;
$greyColor: #e3e3e3;
$bbError: #C92C2C;
$medium: #383838;
$dark: #1b1b1b;
$btnDeactivated: #b5b5b5;
$success: #6fd361;
$tooltipFontColor: $bbPrimaryPurpleColor;
$tooltipBackgroundColor: #f0f0f0;
$darkNeutralPurpleColor: #262f3b;
$lightNeutralPurpleColor: #f4f3fa;
$darkPurpleColor: #1c162c;
$lightPurpleColor: #4b5d76;
$lightGreyColor: #d2d7dd;


// NEW FAST FLOW THEME VARIABLES

$blueVioletColor: $bbPrimaryPurpleColor;

$saltBox: $neutralPurple100;
$neutralPurple600: #433369;
$neutralPurple900: $darkPurpleColor;
$purpleAccent: #D1C4FF;
$purpleAccent300: #D9D0FF;
$purpleAccent100: #E6E2FF;

$grey600: #788698;

$gradientWhiteToPurple: linear-gradient(190deg, $bbPrimaryPurpleColor -10.31%, $white 59.29%);
$gradientWhiteToLightPurple: linear-gradient(76deg, $white 0%, $blueViolet25 100%);
$gradientBrightToDarkPurple: linear-gradient(264deg, #8A65EB 11.08%, $blueViolet500 90.04%);
$gradientDarkToBrightPurple: linear-gradient(223.76deg, $bbPrimaryPurpleColor 32.66%, #8A65EB 88.96%);
$gradientPurple:linear-gradient(191deg, $bbPrimaryPurpleColor -11.24%, #7965BF 59.06%);
$gradientDarkToPurple: linear-gradient(191.22deg, $blueViolet600 -11.24%, $bbPrimaryPurpleColor 59.06%);
$gradientAccentLight: linear-gradient(90deg, #8A65EB 0%, $purpleAccent 100%);
$gradientAccentWhite: linear-gradient(232deg, #E6E2FF 0%, #D1C4FF 118.88%);
$backgroundBlack: #141414;

:export {
  tooltipFontColor: $tooltipFontColor;
  darkNeutralPurpleColor: $darkNeutralPurpleColor;
  greyColor: $greyColor;
  successColor: $success;
  blueVioletColor: $blueVioletColor;
  whiteColor: $white;
  dividerColor: $purpleAccent;
  dividerGrey: $lightGreyColor;
  defaultFont: $font-1;
  accentColor: $purpleAccent;
  accentFont: $blueViolet1000;
}
