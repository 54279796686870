@import "../../mixins.scss";

.button2 {
    @include fr-cc;
    @include h4-caption;
    width: fit-content;
    box-sizing: border-box;
    font-weight: 700;
    padding: 9.5px 20px;
    border-radius: 2000px;
    text-align: center;
    cursor: pointer;
    border: none;
    text-decoration: none;
    text-transform: uppercase;
    transition: 0.5s;
    &:hover {
        filter: brightness(90%);
    }
    &:disabled {
        opacity: 0.25;
        cursor: not-allowed;
    }
    &.xl {
        padding: 14.5px 25px;
        p {
            @include h4-caption;
            color: unset;
            font-weight: unset;
            width: unset;
        } 
        @include sm-md {
            padding: 18px 30px;
        }
    }
    &.lg {
        padding: 9.5px 20px;
        p {
            @include h5-caption;
            color: unset;
            font-weight: unset;
            width: unset;
        }
        @include sm-md {
            padding: 14.5px 25px;
        }
    }
    &.md {
        padding: 4.5px 15px;
        p {
            @include h5-caption;
            color: unset;
            font-weight: unset;
            width: unset;
        }
        svg {
            width: 13px;
            height: 13px;
        }
        @include sm-md {
            padding: 9.5px 20px;
        }
    }
    &.sm {
        padding: 2px 10px;
        p {
            @include h5-caption;
            color: unset;
            font-weight: unset;
            width: unset;
        }
        svg {
            width: 13px;
            height: 13px;
        }
        @include sm-md {
            padding: 4.5px 15px;
        }
    }
    &.xs {
        padding: 2px 10px;
        p {
            @include h6-caption;
            color: unset;
            font-weight: unset;
            width: unset;
        }
        svg {
            width: 13px;
            height: 13px;
        }
    }
  &.dark{
    background-color: $bbPrimaryPurpleColor;
    color: $white;
    svg {
        fill: $white;
    }
  }
  &.light {
    background-color: $purpleAccent;
    color: $blueViolet1000;
    svg {
        fill: $blueViolet1000;
    }
  }
  &.white {
    background-color: $white;
    color: $bbPrimaryPurpleColor;
    svg {
        fill: $bbPrimaryPurpleColor;
    }
  }
  &.dark.outline{
    background-color: transparent;
    color: $bbPrimaryPurpleColor;
    border: 1.5px solid $bbPrimaryPurpleColor;
    svg {
        fill: $bbPrimaryPurpleColor;
    }
  }
  &.light.outline{
    background-color: transparent;
    color: $white;
    border: 1.5px solid $white;
    svg {
        fill: $white;
    }
  }
  &.white.outline{
    background-color: transparent;
    color: $purpleAccent;
    border: 1.5px solid $purpleAccent;
    svg {
        fill: $purpleAccent;
    }
  }
  &.dark.tertiary, &.dark.linkButton{
    background-color: transparent;
    color: $bbPrimaryPurpleColor;
    svg{
        fill: $bbPrimaryPurpleColor;
    }
  }
  &.light.tertiary, &.light.linkButton{
    background-color: transparent;
    color: $purpleAccent;
    svg{
        fill: $purpleAccent;
    }
  }
  &.white.tertiary, &.white.linkButton{
    background-color: transparent;
    color: $white;
    svg{
        fill: $white;
    }
  }
  &.linkButton{
    padding: 0;
    gap: 8px;
    text-transform: unset;
    p {
        text-decoration: underline;
        padding: 3px 0 0 0;
        font-weight: 500;
    }
  }
  &.full{
    width: 100%;
  }
  p {
    padding: 3px 10px 0px 10px;
  }
  svg {
    width: 15px;
    height: 15px;
    flex-shrink: 0;
    flex-grow: 0;
    fill: inherit;
    * {
      fill: inherit;
    }
  }
}