@import "../../mixins.scss";

.container{
    width: 100%;
    @include fullViewportHeightwHeader;
    display: flex;
    padding: 30px 10px 30px 25px;
    align-items: flex-start;
    gap: 10px;
    @include nav-bp {
        padding: 90px 45px 90px 120px;
        gap: 45px;
    }
    .mySwiper {
        width: 100%;
        height: 100%;
        .swiperSlide {
            text-align: center;
            font-size: 18px;
            color: #fff;
            max-height: unset;
            /* Center slide text vertically */
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    .navigation{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        z-index: 10;
        .prevArrow, .nextArrow{
            display: flex;
            padding: 12px;
            justify-content: center;
            align-items: center;
            svg {
                width: 18px;
                height: 18px;
                fill: $white;
            }
        }
        .swiperPagination{
            display: flex;
            flex-direction: column;
            gap: 20px;
            position: unset;
            right: unset;
            top: unset;
            transform: unset;
        }
    }
    .bullet{
        width: 8px;
        height: 8px;
        box-shadow:
              0 0 0 3px $backgroundBlack,
              0 0 0 5px $white;
        border-radius: 2000px;
        background-color: $backgroundBlack;
    }
    .activeBullet{
        width: 8px;
        height: 8px;
        box-shadow:
              0 0 0 3px $backgroundBlack,
              0 0 0 5px $purpleAccent;
        border-radius: 2000px;
        background-color: $purpleAccent;
    }
    &.light {
        .mySwiper .swiperSlide{
            color: $black;
        }
        .bullet{
            box-shadow:
                  0 0 0 3px $white,
                  0 0 0 5px $bbPrimaryPurpleColor;
            background-color: $white;
        }
        .activeBullet{
            box-shadow:
                  0 0 0 3px $white,
                  0 0 0 5px $bbPrimaryPurpleColor;
            background-color: $bbPrimaryPurpleColor;
        }
        .navigation .prevArrow svg, .navigation .nextArrow svg{
            fill: $bbPrimaryPurpleColor;
        }
    }
}
