@import "../../mixins.scss";

.container {
  display: flex;
  flex-direction: column;
  @include fullViewportHeight;
  width: 100%;
  align-items: center;
  background-color: rgba($backgroundBlack, 0.75);
  .spin {
    color: $purpleAccent;
    animation: spin 0.8s infinite linear;
    margin: auto;
    width: 35px;
    height: 30px;
    @include nav-bp {
      width: 105px;
      height: 100px;
    }
  }

  @keyframes spin {
    0% {
      transform: rotateZ(0deg);
    }
    100% {
      transform: rotateZ(360deg);
    }
  }
}

.navbar {
  @include f-c;
  align-items: center;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 2000;
  width: 100%;
  height: $nav-height-m;
  background: $black;
  @include nav-bp {
    height: $nav-height;
    padding: 0px 25px;
  }
  @include lg-xl{
    padding: 0px 120px;
  }
}
.navbarLogo {
  display: flex;
  max-width: $max-width-no-padding;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: center;
  padding: 0 10px 0 20px;
  @include nav-bp {
    padding: 0;
  }
}
.logo {
  margin: 25px 0px;
  height: 30px;
  width: fit-content;
  flex-shrink: 0;
  path {
    fill: $white;
  }
  @include nav-bp {
    height: 35px;
  }
}
