@import "../../mixins.scss";

.returnToSearch {
    background-color: transparent;
    border: none;
    width: 100%;
    @include fr-cc;
    gap: 5px;
    cursor: pointer;
    svg path {
      fill: $lightNeutralPurpleColor;
    }
    .searchText {
      font: 500 normal 16px/150% $font-1;
      color: $lightNeutralPurpleColor;
      text-decoration: underline;
    }
}