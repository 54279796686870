@import "../../mixins.scss";

.pages-card {
  background-color: #2a2733;
  padding: 0px 30px 50px 30px;
  width: 100%;
  box-sizing: border-box;
  height: max-content;
  .pages-card-container {
    @include f-cc;
    height: 100%;
    .pages-card {
      transform: translateY(-100px);
      height: max-content;
      border-radius: 20px;
      padding: 50px 30px 100px 30px;
    }
  }
}
