@import "../../mixins.scss";

.divider {
  @include f-r;
  @include ai-c;
  width: 100%;
  opacity: 40%;
  .divider-line {
    width: 100%;
    height: 4px;
    background: #8A65EB;
  }
}