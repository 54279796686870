@import "../../mixins.scss";

.dropdown {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    width: 100%;
    gap: 5px;
    .labelContainer{
        @include f-r-(10px);
        align-items: flex-start;
        align-self: stretch;
        .optional {
          @include h6-caption;
          font-weight: 500;
          color: rgba($black, 0.75);
        }
    }
    label {
      color: $black;
      @include h5-caption;
      font-weight: 500;
      width: 100%;
      margin:0;
      padding:0;
    }
    .captionContainer {
        width: 100%;
        @include f-c-(5px);
        .caption{
          align-self: stretch;
          @include h6-caption;
          color: $black;
        }
      }
    .dropdownContainer {
      display: flex;
      padding: 0px 0px 5px 0px;
      flex-direction: column;
      align-items: flex-start;
      gap: 5px;
      width: 100%;
      text-wrap: wrap;
      @include h5-caption;
      font-weight: 700;
      color: $blueVioletColor;
      border:0;
      border-bottom: 1px solid $blueViolet400;
      appearance: none;
      background-image: url("../../assets/SVGs/arrow-down.svg");
      background-repeat: no-repeat;
      background-position: right 0.7rem top 50%;
      background-size: 1rem auto;
      background-color: transparent;
      &:hover {
        border-bottom-color: rgba($blueViolet400, .8);
      }
      &:required:invalid{
        color: rgba($blueVioletColor, .4);
      }
      &:focus, &:active {
        border-bottom: 2px solid $blueVioletColor;
        outline: none;
      }
    }
  }