@import "../../mixins.scss";

.snapSection {
  min-height: 100vh;
  min-height: 100svh;
  padding-top: $nav-height-m;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  scroll-snap-align: start;
  position: relative;
  @include nav-bp {
    padding-top: $nav-height;
  }
  .left{
    position: absolute;
    overflow-y: hidden !important;
    z-index: 100;
    bottom: 0;
    left: 0;
    margin: 0 0 -1px 0;
    width: 60px;
    height: 60px;
    svg{
      width: 60px;
      height: 60px;
      fill: $backgroundBlack;
    }
    @include nav-bp {
      width: 120px;
      height: 120px;
      svg{
        width: 120px;
        height: 120px;
      } 
    }
  }
  .right{
    position: absolute;
    overflow-y: hidden !important;
    z-index: 100;
    top: 0;
    right: 0;
    width: calc(60px + $nav-height-m);
    height: calc(60px + $nav-height-m);
    svg{
      width: calc(60px + $nav-height-m);
      height: calc(60px + $nav-height-m);
      fill: $backgroundBlack;
    }
    margin:-1px 0 0 0;
    @include nav-bp {
      width: calc(120px + $nav-height);
      height: calc(120px + $nav-height);
      svg{
        width: calc(120px + $nav-height);
        height: calc(120px + $nav-height);
      } 
    }
  }
  &.short{
    min-height: unset;
  }
}
.light {
  @include whiteBG;
}