@import "../../mixins.scss";

@mixin buttonStyle {
  font-family: $font-1;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 21px;
}
.width {
  width: 100%;
  & > div {
    width: 100%;
  }
}
.articleList {
  background-color: #1a1632;
  display: flex;
  flex-direction: column;
  gap: 20px;
  .titleDiv {
    &.height {
      height: 400px;
      span {
        @include xs {
          bottom: -10%;
        }

        @include lg-xl {
          bottom: -13%;
        }
      }
      @include xs {
        height: 100px;
      }
      @include sm {
        height: 200px;
      }
      @include md {
        height: 250px;
      }

      @include lg-xl {
        height: 400px;
      }
    }
    &.height1 {
      height: 400px;
      span {
        @include xs {
          bottom: -11%;
        }
        @include lg-xl {
          bottom: -17%;
        }
      }
      @include xs {
        height: 100px;
      }
      @include sm {
        height: 200px;
      }
      @include md {
        height: 250px;
      }
      @include lg-xl {
        height: 400px;
      }
    }
    &.advancingTitle {
      span {
        @include xs {
          font-size: 32px;
          line-height: 40px;
        }
        @include sm {
          font-size: 66px;
          line-height: 68px;
        }
        @include md {
          font-size: 85px;
          line-height: 90px;
        }
        @include lg-xl {
          font-size: 140px;
          line-height: 155px;
        }
        position: absolute;
        bottom: -10%;
        padding: 0 6%;
      }
    }
    &.artistLikeHeight {
      @include xs {
        height: 100px;
      }
      @include sm {
        height: 200px;
      }
      @include md {
        height: 250px;
      }
      @include lg-xl {
        height: 300px;
      }
      span {
        bottom: -15%;
      }
    }
    @include xs {
      height: 100px;
    }
    @include sm {
      height: 200px;
    }
    @include md {
      height: 250px;
    }
    @include lg-xl {
      height: 400px;
    }
    background-image: url("../../assets/images/instruments-bg.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
    width: 100%;
    position: relative;
    span {
      @include xs {
        font-size: 40px;
        line-height: 49px;
      }
      @include sm {
        font-size: 80px;
        line-height: 70px;
      }
      @include md {
        font-size: 110px;
        line-height: 100px;
      }
      @include lg-xl {
        font-size: 177px;
        line-height: 160px;
      }
      font-family: $font-1;
      font-style: normal;
      font-weight: 700;
      font-size: 177px;
      line-height: 160px;
      background: linear-gradient(247.01deg, #fcdc2f 12.39%, #f1977c 120.41%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      mix-blend-mode: normal;
      position: absolute;
      bottom: -10%;
      padding: 0 6%;
      @media screen and (min-width: 1180px) and (max-width: 1224px) {
        font-size: 110px;
        line-height: 136px;
        bottom: -14%;
      }
      @media screen and (min-width: 1180px) and (max-width: 1200px) {
        line-height: 100px;
      }
    }
  }
  .searchMainDiv {
    padding: 2% 6%;
    display: flex;
    &.paddingTop {
      padding-top: 50px;
    }
    @include xs {
      flex-direction: column-reverse;
      gap: 20px;
    }
    @include lg-xl {
      gap: unset;
      flex-direction: row;
      align-items: flex-end;
    }
    .searchDiv {
      display: flex;
      flex: 2 1;
      position: relative;
      &.flex {
        flex: 0.7;
      }

      .select {
        padding: 10px 15px;
        border-radius: 24px;
        outline: none;
        background-color: #8675b8;
        letter-spacing: 0.12em;
        text-transform: uppercase;
        color: #141020;
        @include buttonStyle();
        border: 0px;
        font-size: 16px;
        line-height: 20px;
        position: absolute;
        :global {
          .MuiSelect-select {
            padding: 1px 15px 0px;
          }
        }
        @media screen and (max-width: 1450px) {
          padding: 12px 15px;
          font-size: 12px;
        }
      }
      .inputStyle {
        width: 90%;
        display: flex;
        margin: auto;
        input {
          padding: 9px 65px;
          background-color: transparent;
          outline: none;
          border: 2px solid #8675b8;
          border-left: none;
          border-right: none;
          color: white;
          @include buttonStyle();
          width: 100%;
          @media screen and (max-width: 1024px) {
            padding: 9px 75px;
          }
          @media screen and (max-width: 767px) {
            padding: 9px 85px;
          }
        }
      }
      button {
        padding: 11px 20px;
        background: #fe936f;
        border-radius: 24px;
        color: #3e3374;
        letter-spacing: 0.12em;
        text-transform: uppercase;
        outline: none;
        border: 0;
        @include buttonStyle();
        cursor: pointer;
        position: absolute;
        right: 0;
      }
      @media screen and (max-width: 479px) {
        .select {
          padding: 11px;
        }

        button {
          padding: 11px 10px;
        }
      }
    }
    .rightDiv {
      flex: 1 1;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: 20px;
    }
  }
  :global {
    .swiper-slide {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
    }
    .swiper-slide-active {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    .swiper-pagination {
      display: flex;
      justify-content: center;
      transform: translate(0px, -40px);
    }
    .swiper-pagination-lock {
      display: none;
    }
    .swiper-button-disabled {
      cursor: not-allowed !important;
      opacity: 0.6;
    }
    .MuiInputBase-root fieldset {
      border: none;
    }
  }
  .footerDiv {
    padding: 2% 6%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    a {
      font-weight: 900;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: 0.12em;
      text-transform: uppercase;
      color: #f7c948;
      text-decoration: none;
    }
    .tiktokDiv {
      color: #fe936f;
    }
    img {
      margin-left: 10px;
    }
  }
  .footerDiv1 {
    display: flex;
    flex-direction: column;
    gap: 10px;
    a {
      font-weight: 900;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: 0.12em;
      text-transform: uppercase;
      color: #f7c948;
      text-decoration: none;
    }
    .tiktokDiv {
      color: #fe936f;
    }
    img {
      margin-left: 10px;
    }
  }
}
.swiperWrapper {
  &.swiperPagination {
    :global(.swiper-pagination-bullets) {
      display: none;
    }
  }
  :global(.swiper-wrapper) {
    flex-wrap: wrap;
    flex-direction: unset;
    row-gap: 40px;

    @media screen and (max-width: 767px) {
      width: 100%;
      align-items: center;
    }
  }
  :global(.swiper-slide) {
    display: grid;
    grid-template-columns: repeat(3, [col-start] 1fr);
    gap: 30px;
    @media screen and (max-width: 767px) {
      grid-template-columns: repeat(2, [col-start] 1fr);
    }
    @media screen and (max-width: 500px) {
      grid-template-columns: repeat(1, [col-start] 1fr);
    }
  }
  :global(.swiper-pagination) {
    display: flex;
    justify-content: center;
  }
  :global(.swiper-slide) {
    margin-top: 0px !important;
  }
  :global(.swiper-slide-active) {
    max-height: unset;
  }
}
.arrowContainer {
  display: flex;
  justify-content: space-between;
  &.showArrow {
    display: none;
    @media screen and (max-width: 767px) {
      display: flex;
    }
  }
}
.gridContainer {
  display: grid;
  padding: 2% 6%;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  justify-items: center;
  &.marginBottom {
    margin-bottom: 50px;
  }
  @media screen and (max-width: 767px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: 500px) {
    grid-template-columns: repeat(1, 1fr);
  }
  &.pressPage {
    h3 {
      color: #fe936f;
    }
    a {
      color: #fe936f;
    }
  }
  &.testimonialStyle {
    padding: 0%;
    gap: 30px;
  }
}

.articleContainer {
  @include f-c-(30px);
  width: 100%;
  margin-top: 40px;
  &.marginTop {
    margin-top: 0px !important;
  }
  .articleTop{
    @include f-c-(15px);
    align-items: flex-start;
    width: 100%;
    .category {
      font: 700 normal 10px/150% $font-1;
      text-transform: uppercase;
      padding: 10px 0;
      color: $bbPrimaryPurpleColor;
      border-bottom: 1px solid $bbPrimaryPurpleColor;
      width: 100%;
      @include md {
        font-size: 12px;
      }
    }
    .titleContainer{
      @include f-c-(5px);
      padding-top: 5px;
      width: 100%;
      @include md {
        gap: 10px;
      }
      .title {
        @include h6;
        color: $bbPrimaryPurpleColor;
        font-weight: 600;
      }
      .author{
        @include h5-caption;
        color: $black;
      }
    }
    .desc {
      @include h5-caption;
      font-weight: 400;
      word-break: break-word;
      color: $black;
    }
    .imgContainer {
      aspect-ratio: 16/9;
      width: 100%;
      max-width: 100%;
      img {
        width: 100%;
        height: 100%;
        border-radius: 6px;
        object-fit: cover;
        object-position: center;
      }
    }
    .tagsLinks {
      @include f-r;
      align-items: center;
      width: 100%;
      flex-wrap: wrap;
      div {
        padding: 0 3px;
      }
      .link {
        font: 500 normal 12px/142% $font-1;
        text-decoration: underline;
        color: $bbPrimaryPurpleColor;
        cursor: pointer;
        @include md {
          font-size: 14px;
        }
      }
      .tags {
        font: 700 normal 12px/150% $font-1;
        padding-right: 4px;
        text-transform: uppercase;
        color: $blueViolet900;
        @include md {
          font-size: 14px;
        }
      }
      .linkComma {
        color: $black;
      }
    }
  }
  .noStylelink {
    text-decoration: none;
  }

  .articleLink {
    padding-left: 0 !important;
    justify-content: flex-start;
    p {
      padding-left: 0;
    }
  }
}
.articleContainer.light{
  .articleTop{
    .category {
      color: $purpleAccent;
      border-bottom: 1px solid $purpleAccent;
    }
    .titleContainer{
      .title {
        color: $purpleAccent;
      }
      .author{
        color: $white;
      }
    }
    .desc {
      color: $white;
    }
    .tagsLinks {
      .link {
        color: $purpleAccent;
      }
      .linkComma {
        color: $white;
      }
      .tags {
        color: $white;
      }
    }
  }
}

.articlePagination {
  padding: 0% 6% 5% 6%;
  &.marginTop {
    margin-top: 80px;
    @media screen and (max-width: 500px) {
      margin-top: 0px;
    }
  }
  &.bg {
    background-color: #1a1632;
  }
  :global(.MuiStack-root) {
    position: relative;
  }
  :global(.MuiPaginationItem-ellipsis) {
    color: #dddbf1;
  }

  li:first-child {
    position: absolute;
    left: 0;
  }
  li:last-child {
    position: absolute;
    right: 0;
  }
  &.extraPadding {
    padding: 0% 10% 5% 10%;
  }
}

.articlePagination li:hover:not(.active) a {
  background-color: #ddd;
}
.pagination {
  :global(.MuiButtonBase-root-MuiPaginationItem-root) {
    .Mui-selected:hover {
      background-image: url("../../assets/SVGs/bullet-inactive.svg");
    }
  }
  :global(.MuiButtonBase-root) {
    background-image: url("../../assets/SVGs/bullet-inactive.svg");
  }
  ul {
    button {
      color: transparent;
      border-radius: 0px;
      background: transparent;
      height: 30px;
      margin: 5px;
      @media screen and (max-width: 500px) {
        margin: 0px;
      }
    }
  }
  ul {
    :first-child {
      button {
        display: block;
        border-radius: none;
        background-color: transparent;
        padding-right: 10px;
        border: none;
        background-image: none;
      }
    }
    :last-child {
      button {
        display: block;
        background-color: transparent;
        padding-left: 10px;
        border: none;
        border-radius: none;
        background-image: none;
      }
    }
  }
}
.noData {
  min-height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    color: $white;
    font: 700 normal 16px/150% $font-1;
  }
}

.articleDiv {
  display: flex;
  flex-direction: column;
  .footerDiv {
    @include xs {
      font-size: 16px;
      line-height: 18px;
    }
    @include md {
      font-size: 27px;
      line-height: 30px;
    }
    font-weight: 700;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #dddbf1;
    text-decoration: none;
    padding: 0% 10% 2%;
  }
}
