@import "../../mixins.scss";

.step {
  @include f-c;
  position: relative;
  .stepWord {
    font: 700 27px/133% $font-1;
    background: $gradientBrightToDarkPurple;
    @include text-gradient;
    position: absolute;
    z-index: 1;
    text-transform: uppercase;
    top: 55px;
    left: 0px;
    @include md {
      font-size: 30px;
      top: 85px;
    }
  }
  .stepNumber {
    font: 400 100px/normal $font-acumin;
    @include font-acumin-r;
    background: $gradientBrightToDarkPurple;
    @include text-gradient;
    opacity: 0.35;
    @include md {
      font-size: 148px;
    }
  }

  .stepContent {
    @include f-c-(9px);
    align-self: stretch;
    h3 {
      @include h3-caption;
      font-weight: 700;
      z-index: 2;
      position: unset;
      top: 45%;
      color: $bbPrimaryPurpleColor;
    }
    .stepDivider {
      margin-bottom: 9px;
    }
    p {
      @include h4-caption;
      color: $black;
    }
  }
  &.light{
    .stepWord {
      background: $gradientAccentWhite;
      @include text-gradient;
    }
    .stepNumber {
      background: $gradientAccentWhite;
      @include text-gradient;
      opacity: 0.35;
    }
    .stepContent{
      .stepDivider{
        background-color: $purpleAccent !important;
      }
      h3 {
        color: $purpleAccent;
      }
      p{
        color: $white;
      }
    }
  }
}