@import "../../mixins.scss";

.mainNavContainer {
  @include f-c;
  align-items: center;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 2000;
  width: 100%;
  height: $nav-height-m;
  background: $black;
  @include nav-bp {
    height: $nav-height;
    padding: 0px 25px;
  }
  @include lg-xl{
    padding: 0px 120px;
  }
  a {
    text-decoration: none;
  }
}

  .primaryNavbarContainer {
    max-width: $max-width-no-padding;
    width: 100%;
    display: none;
    justify-content: space-between;
    align-items: flex-start;
    flex-shrink: 0;
    @include nav-bp {
      display: flex;
    }
    .navLinksContainer {
      display: inline-flex;
      justify-content: flex-end;
      align-items: center;
    }
    
    .navbarLeft {
      display: flex;
      align-items: flex-start;
      gap: 25px;
      .navLogo {
        text-decoration: none;
        display: flex;
        align-items: center;
        svg {
          width: fit-content;
          margin: 25px 0px;
          height: 35px;
          path {
            fill: $white;
          }
        }
      }
      .dropdowns{
        display: flex;
        align-items: flex-start;
        .drawerContainer{
          width: 150px;
        }
        .title {
          padding: 30px 12.5px;
          gap: 0px;
          justify-content: flex-start;
          p {
            padding: 3px 12.5px 0 12.5px;
            white-space: nowrap;
            width: unset;
          }
        }
      }
    }
    .navbarRight {
      display: flex;
      align-items: center;
      padding: 27.5px 0px;
      .navLinksContainer {
        display: flex;
        align-items: center;
        gap: 15px;
      }
    }
  }

.navMobileMenuHeader {
  width: 100%;
  height: 100%;
  padding: 0 10px 0 20px;
  z-index: 2000;
  background: $black;
  @include xs {
    @include f-r;
    @include c-sb;
    box-sizing: border-box;
  }
  @include nav-bp {
    display: none;
  }
  .navMobileLogo {
    display: flex;
    align-self: stretch;
    align-items: center;
    svg {
      height: 30px;
      width: auto;
      path {
        fill: $white;
      }
    }
  }
  .navMobileMenuButton {
    @include fr-cc;
    z-index: 10;
    cursor: pointer;
    padding: 12px;
    svg {
      width: 16px;
      height: 16px;
      fill: $white;
    }
  }
}

.mobileDrawer {
  &:global(.MuiDrawer-root) {
    z-index: 50;
  }
  &.open :global(.MuiPaper-root) {
    transform: translateY(0) !important;
    overflow-y: unset;
  }
  :global {
    .MuiBackdrop-root {
      top: 60px;
    }
    .MuiPaper-root {
      top: $nav-height-m;
      transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) !important;
      background-color: transparent;
    }
    .MuiBox-root {
      border-radius: 0 0 15px 15px;
      background-color: $black;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    }
  }
  .mobileNavPopup {
    @include fullViewportHeightwHeader;
    background-color: rgba($backgroundBlack, 0.75);
    .mobilePrimaryNav {
      @include nav-bp {
        gap: 25px;
        height: 40px;
      }
      display: flex;
      flex-direction: row;
      justify-content: center;
      flex-wrap: wrap;
      height: auto;
      background-color: $neutralPurple50;
      gap: 0;
      align-self: stretch;
      & > a {
        padding: 10px 20px;
        color: $blueViolet700;
        font-weight: 500;
      }
    }
    .mobileEmpty{
      height: 100%;
    }
  }
  
  .mobileLoginContainer {
    display: flex;
    padding: 15px 20px 10px 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 100%;
    .loginBtn{
      width: 100%;
    }
  }
}

.drawerContainer{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  .title{
    display: flex;
    padding: 10px 24px 10px 20px;
    gap: 20px;
    justify-content: center;
    align-items: center;
    width: 100%;
    p {
      @include h4-caption;
      padding-top: 3px;
      font-weight: 700;
      color: $white;
      width: 100%;
    }
    .arrow {
      width: 12.5px;
      height: 12.5px;
      flex-shrink: 0;
      fill: $white;
    }
  }
  .title.open {
    p {
      color: $purpleAccent;
    }
    .arrow {
      fill: $purpleAccent;
    }
  }
  .drawer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    .navLink{
      display: flex;
      padding: 10px 24px 10px 20px;
      gap: 20px;
      justify-content: center;
      align-items: center;
      p {
        @include h4-caption;
        font-weight: 700;
        color: $white;
      }
    }
    &.closed {
      display: none !important;
    }
  }
}

.drawerContainer.open {
  background-color: #242424;
}