@import "../../mixins.scss";

.glossBox {
  @include f-c;
  @include fs-c;
  @include xs {
    width: 100%;
  }
  @include bp {
    width: 100%;
  }
  .click{
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
  }
  .gbHeader {
    @include f-r-(22px);
    align-items: center;
    @include jc-sb;
    padding: 16px 0px 20px 0px;
    border-top: 2px solid $blueViolet700;
    z-index: 2;
    width: 100%;
    box-sizing: border-box;
    @include nav-bp {
      padding: 25px 0px 30px 0px;
    }
    .gbArrow {
      @include ai-fe;
      svg {
        @include h-w-(24px);
        fill: $bbPrimaryPurpleColor;
      }
    }
    .gbTerm {
      @include fr-cc;
      gap: 15px;
      font: 700 27px/110% $font-acumin;
      @include font-acumin-sb;
      @include md {
        font-size: 32px;
      }
      &.gbClosed {
        color: $blueViolet600;
      }
      &.gbOpen {
        color: $bbPrimaryPurpleColor;
      }
      .iconDiv {
        width: 20px;
          height: 20px;
          @include md {
            width: 30px;
            height: 30px;
          }
        svg {
          width: 100%;
          height: 100%;
          fill: $bbPrimaryPurpleColor;
        }
      }
    }
  }
  .gbDefinition {
    @include h5-caption;
    color: $black;
    z-index: 1;
    position: relative;
    transition: all 0.3s ease-in-out;
    a {
      color: $bbPrimaryPurpleColor;
    }
  }
  &.light{
    .gbHeader {
        border-top: 2px solid $purpleAccent;
        .gbArrow {
        svg {
            fill: $purpleAccent;
        }
        }
        .gbTerm {
        &.gbClosed {
            color: $white;
        }
        &.gbOpen {
            color: $blueViolet25;
        }
        .iconDiv {
            svg {
            fill: $purpleAccent;
            }
        }
        }
    }
    .gbDefinition {
        color: $white;
        a {
        color: $purpleAccent;
        }
    }
  }
}

.linkDiv {
  padding-left: 0px !important;
  p {
    padding-left: 0px;
  }
}
