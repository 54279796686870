@import "../../mixins.scss";
.testimonials-carousel {
  .swiper-carousel {
    @include xs {
      @include f-cc;
      min-height: unset;
    }
    @include xl {
      min-height: unset;
    }
    width: 100%;
    .sc-component {
      @include xs {
        @include f-r;
        @include c-c;
        // padding-bottom: 80px;
      }
      @include xl {
        position: relative;
      }
      .swiper-wrapper {
        @include xs {
          width: calc(100% - 20px);
          order: 2;
        }
        @include xl {
          width: 100%;
          order: 1;
        }
        .swiper-slide {
          @include xs {
            @include f-r;
            @include fs-c;
            margin-right: 4%;
          }
          @include md {
            width: 70%;
          }
          @include xl {
            width: 70%;
          }
          .sc-divider {
            .sc-slide {
              @include xs {
                @include f-c;
                @include c-fs;
                padding-top: 60px;
              }
              @include md {
                @include f-r;
                @include fs-fs;
              }
              box-sizing: border-box;
              width: 100%;
              .sc-image {
                @include f-c;
                @include ai-fs;
                @include xs {
                  width: 264px;
                  height: 300px;
                }
                @include xl {
                  position: relative;
                  width: 304px;
                  height: 345px;
                  overflow: hidden;
                }
                width: 704px;
                height: 800px;
                flex-shrink: 0;
                picture{
                  width: 100%;
                  height: 100%;
                }
                .sc-image-img {
                  @include xs {
                    max-width: 264px;
                    max-height: 300px;
                    top: 20%;
                    left: 10%;
                  }
                  @include xl {
                    max-width: 352px;
                    max-height: 400px;
                    top: 10%;
                    left: 10%;
                  }
                  border-radius: 5%;
                  background-image: linear-gradient(
                    to left,
                    #0a0810 46.01%,
                    transparent
                  );
                  height: 100%;
                  object-fit: cover;
                }
              }
              .sc-content {
                @include f-c;
                @include xs {
                  padding: 10px 20px;
                }
                @include bp {
                  padding-left: 20px;
                }
                .sc-quote {
                  @include xs {
                    margin-bottom: 20px;
                    font: normal 20px/28px $font-1;
                    // transform: translateX(-15%);
                  }
                  @include xl {
                    margin-bottom: 75px;
                    font: normal 28px/31px $font-1;
                    transform: translateX(-12%);
                  }
                  overflow-wrap: break-word;
                  letter-spacing: 1.2px;
                  color: #dddbf1;
                  text-align: left;
                  opacity: 100%;
                }
                .sc-artist-name {
                  @include xs {
                    font: 600 20px/36px $font-1;
                  }
                  @include md {
                    font: 600 20px/36px $font-1;
                  }
                  @include xl {
                    font: 600 30px/54px $font-1;
                  }
                  color: #8675b8;
                  letter-spacing: 1.2px;
                  text-align: left;
                }
                .sc-monthly-listeners {
                  @include xs {
                    font: normal 16px/24px $font-1;
                  }
                  @include md {
                    font: normal 16px/24px $font-1;
                  }
                  @include xl {
                    font: normal 24px/36px $font-1;
                  }
                  color: #dddbf1;
                  letter-spacing: 1px;
                  text-align: left;
                }
              }
            }
          }
        }
      }
    }
    .sc-nav {
      @include xs {
        position: absolute;
        box-sizing: border-box;
        width: 28px;
        padding: auto;
        color: $white;
        background-size: 20px 20px;
        bottom: 55%;
      }
      @include md {
        @include f-cc;
        position: absolute;
        height: 64px;
        width: 64px;
        color: $black;
        background-color: transparent;
        border-radius: 50%;
        bottom: unset;
        top: 0px;
      }
      z-index: 10;
      cursor: pointer;
    }
    .sc-previous {
      @include xs {
        order: 1;
        left: 5px;
      }
      @include md {
        order: 2;
        left: -7px;
      }
      @include xl {
        left: -16px;
      }
    }
    .sc-next {
      @include xs {
        right: 5px;
      }
      @include md {
        right: -16px;
      }
      order: 3;
    }
  }
}
