@import "../../mixins.scss";

.snapSection {
  padding-top: $nav-height-m;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  scroll-snap-align: start;
  position: relative;
  @include nav-bp {
    padding-top: $nav-height;
  }
  .right{
    position: absolute;
    overflow-y: hidden !important;
    z-index: 100;
    bottom: 0;
    right: 0;
    width: 60px;
    height: 60px;
    svg{
      transform: rotate(90deg);
      width: 60px;
      height: 60px;
      fill: $backgroundBlack;
    }
    margin:0 0 -1px 0;
    @include nav-bp {
      width: 120px;
      height: 120px;
      svg{
        width: 120px;
        height: 120px;
      } 
    }
  }
  .landingCtn {
    align-items: center;
    display: flex;
    flex-direction: column;
    max-width: $max-width;
    width: 100%;
    padding: 30px 25px;
    gap: 50px;
    @include nav-bp{
      flex-direction: row;
      padding: 90px 120px;
      gap: 100px;
    }
    .headingCtn {
      @include f-c-(30px);
      width: 100%;
      .titleContainer {
        @include h1;
        width: 100%;
        .primary {
          color: $white;
        }
        .secondary {
          background: $gradientAccentWhite;
          @include text-gradient;
        }
      }
      .subHeading {
        @include h2-caption;
        font-weight: 400;
        color: $white;
      }
    }
  }
  &.light{
    @include whiteBG;
    .landingCtn {
      .headingCtn {
        .titleContainer {
          .primary {
            color: $black;
          }
          .secondary {
            background: $gradientBrightToDarkPurple;
            @include text-gradient;
          }
        }
        .subHeading {
          color: $black;
        }
      }
    }
  }
}