@import "../../mixins.scss";

.toast {
  @include f-r;
  position: fixed;
  box-sizing: border-box;
  min-width: 100px;
  top: 25px;
  color: $white;
  background-color: $backgroundBlack;
  border: 1px solid $white;
  border-radius: 5px;
  margin-right: 10px;
  z-index: 100;
  .logo {
    background-size: 25px auto;
    padding: 10px;
    border-right: 1px solid $white;
  }
  .content {
    @include f-c;
    @include fs-c;
    padding: 10px;
    .title {
      font: 700 24px/28px $font-1;
    }
    .text {
      font: 500 18px/28px $font-1;
    }
  }
}
