@import "../../mixins.scss";


$line-height: 28px;
$font-size: 27px;
$weight: 500;
$hr-thickness: 1px;
.see-more {
  width: 100%;
  .sm-button {
    @include f-r-(5px);
    @include c-c;
    width: 100%;
    background: none;
    border: none;
    padding: 0px;
    cursor: pointer;
    font: normal normal $weight 27px/28px $font-1;
    .sm-button-strikethrough {
      border: none;
      border-top: 1px solid $white;
      width: 100%;
    }
    .sm-button-text {
      color: $white;
      transform: translateY(-5%);
      width: 100%;
      white-space: nowrap;
    }
  }
  .sm-content {
    @include f-r-(20px);
    @include fs-fs;
    margin: 10px 0px;
    flex-wrap: wrap;
  }
}