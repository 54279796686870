@import "../../mixins.scss";
@import "../../sass/constants.scss";

.widgetBox {
  display: flex;
  box-sizing: border-box;
  width: 100%;

  background-clip: border-box;
  background-color: $white;
  border-radius: 15px;
  color: $darkPurpleColor;
  font: 700 18px/28px $font-1;
  overflow: hidden;
  @include xs {
    max-width: 550px;
  }
  @include lg {
    min-width: 398px;
  }
  .content {
    width: 100%;
    @include f-c-(25px);
    align-items: center;
    justify-content: center;
    text-align: center;
    font-style: normal;
    font-weight: 700;
    padding: 25px;
    gap: 25px;
    @include md{
      padding: 45px;
      gap: 30px;
      min-height: 520px;
      justify-content: space-between;
    }
    .headerDiv {
      @include fc-cc;
      gap: 30px;
      .subHeaderDiv {
        @include fc-cc;
        gap: 15px;
        width: 100%;
        .title {
          font: 600 32px/110% $font-acumin;
          @include font-acumin-sb;
          color: $bbPrimaryPurpleColor;
          @include md {
            font-size: 50px;
          }
        }
        .subtitle {
          font: 500 12px/142% $font-1;
          text-align: center;
          color: $darkPurpleColor;
          @include md {
            font-size: 14px;
          }
        }
      }
      .icon {
        fill: $bbPrimaryPurpleColor;
        height: 45px;
        width: 45px;
        @include md {
          height: 60px;
          width: 60px;
        }
        svg {
          height: 100%;
          width: 100%;
        }
      }
    }
    .loginContent {
      @include f-c-(15px);
      width: 100%;
      text-align: left;
      .forgotPasswordDiv {
        @include f-c-(5px);
        align-items: flex-end;
        align-self: stretch;
        .forgotPasswordLink {
          color: $tooltipFontColor;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          text-decoration-line: underline;
          cursor: pointer;
        }
      }
      .redirectToSecureLoginDiv {
        font-weight: 500;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 6px;
        font-size: 16px;
        .link {
          color: $tooltipFontColor;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          text-decoration-line: underline;
          cursor: pointer;
        }
      }
      .loginFormContainer{
        @include f-c-(25px);
      }
    }
    .btn {
      @include f-c-(10px);
      align-items: center;
      width: 100%;
      .signInContainer{
        @include f-r-(5px);
        justify-content: center;
        align-items: center;
        width: 100%;
        order: 2;
        @include md {
          @include f-c;
          order: 1;
        }
        p {
          font: 500 12px/142% $font-1;
          text-transform: none;
          text-align: left;
          @include md {
            width: 100%;
            font-size: 14px;
          }
        }
        a{
          color: $bbPrimaryPurpleColor;
          @include md {
            width: 100%;
          }
        }
      }
      @include md {
        @include f-r-(15px);
      }
      button {
        order: 2;
        width: 100%;
      }
    }
    .secureLoginStyle {
      margin-top: 60px;
    }
    .subtitle1 {
      color: $darkPurpleColor;
      font-size: 18px;
      font-weight: 500;
      line-height: 28px;
      span {
        color: $tooltipFontColor;
      }
    }
    .subtitle2 {
      color: #1f1831;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      span {
        color: $tooltipFontColor;
        text-decoration-line: underline;
        cursor: pointer;
      }
    }
    .sendAgainBtn {
      margin: 0;
    }
  }
  .secureLoginDiv {
    justify-content: unset;
    gap: 30px;
  }
}

.errorClass {
  font-weight: 400;
}

.spinDiv {
  min-height: 470px;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    color: $black;
  }
}
