@import "../../mixins.scss";

.landing {
  background-image: url("../../assets/images/black_fuzzy.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  .landing_container {
    max-width: $max-width;
    width: 100%;
    padding: 30px 25px;
    @include f-c-(30px);
    align-items: flex-start;
    @include md {
      align-items: center;
    }
    @include nav-bp {
      padding: 90px 120px 120px 120px;
      @include f-c-(45px);
    }
    .buttonLink, .mainButton {
      text-decoration: none;
      width: 100%;
      @include md {
        width: unset;
      }
    }
    .landing_logos{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 20px;
      align-self: stretch;
      @include md {
        justify-content: center;
        padding-left: 10px;
      }
      @include nav-bp {
        padding-top: 40px;
      }
      p {
        color: $white;
        font: 400 normal 20px/133% $font-1;
        @include nav-bp {
          font-size: 24px;
        }
      }
      img {
        width: 100px;
        @include nav-bp {
          width: 175px;
        }
      }
      svg {
        flex-shrink: 0;
        flex-grow: 0;
        width: 115px;
        fill: $white;
        @include nav-bp {
          width: 215px;
        }
      }
    }
    .title {
      @include h2;
      color: $white;
      background: unset;
      -webkit-text-fill-color: unset;
      text-align: center;
      margin: 0;
      width: 100%;
      @media screen and (max-width: 767px) {
        text-align: left;
      }
    }
    .subtitle {
      max-width: 800px;
      width: 100%;
      @include h1-caption;
      color: $lightNeutralPurpleColor;
      text-align: center;
    }
    .categories {
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: flex-start;
      @include md {
        align-items: center;
      }
      @include lg-xl {
        gap: 30px;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }
      .category {
        display: flex;
        padding: 15px 30px 15px 15px;
        align-items: center;
        gap: 20px;
        @include md {
          min-width: 315px;
        }
        @include lg-xl {
          min-width: unset;
        }
        svg{
          fill: $white;
          width: 32px;
          height: 32px;
        }
        p {
          color: $lightNeutralPurpleColor;
          text-align: left;
          font: 700 normal 16px/150% $font-1;
          text-transform: uppercase;
          white-space: nowrap;
          @include nav-bp {
            font-size: 18px;
            text-align: center;
          }
        }
      }
    }
    .label_widget {
      @include f-c-(20px);
      box-sizing: border-box;
      max-width: 500px;
      min-width: unset;
      width: 100%;
      background-color: $white;
      border-radius: 15px;
      padding: 20px 25px;
      @include lg {
        min-width: 398px;
        max-width: 510px;
        padding: 40px 50px;
      }
    }
  }
}
