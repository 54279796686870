@import "../../mixins.scss";

.textAreaContainer{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  width: 100%;
  .labelContainer{
    @include f-r-(10px);
    align-items: flex-start;
    align-self: stretch;
    p {
      @include h5-caption;
      font-weight: 500;
      color: $black;
      }
    .optional {
      font: normal 500 12px/150% $font-1;
      color: $neutralPurple600;
    }
  }
}

.textArea {
  display: flex;
  padding: 0px 0px 5px 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  align-self: stretch;
  font: normal 700 14px/142% $font-1;
  color: $blueVioletColor;
  border-radius: 0;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid $blueViolet700;
  &:hover {
    border-bottom-color: rgba($blueViolet700, .8);
  }
  &:focus, &:active {
    border-bottom-color: $blueVioletColor;
    outline: none;
  }
  &::placeholder {
    font: normal 700 14px/142% $font-1;
    color: rgb($blueVioletColor, 0.4);
  }
}